<template>
  <div class="HelpParentView">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'HelpParentView'
};
</script>
